import localStorage from 'localStorage';
import sessionStorage from 'sessionstorage';
import Cookies from 'universal-cookie';
import { caZipRegex } from './constants';
import { isValidPostal } from './validations';

const cookies = new Cookies();

export const isClient = typeof window !== 'undefined';

/**
 * Postal/Zip code functions
 */

export const getCountryFromPostal = (value = '') => {
	if (!isValidPostal(value)) {
		return 'Unknown';
	}
	return caZipRegex.test(value) ? 'CAN' : 'USA';
};

export const savePostalCode = (value) => {
	const key = 'UserPostalCode';
	setLocalStorage(key, value);
};

/**
 *
 */

// Shuffle Aray - pass in an array to have it randomized
export const shuffle = (array) => {
	let currentIndex = array.length,
		tempValue,
		randomIndex;

	while (0 !== currentIndex) {
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		tempValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = tempValue;
	}

	return array;
};

/**
 * Split Array - pass in an array to split into multiple arrays.
 * @param {array} array - Array to split.
 * @param {number} [split] - Default: 2. Amount to split array by.
 */
export const splitArray = (array, split = 2) => [...Array(split).keys()].map((chunk) => array.filter((_, index) => index % split === chunk));

// Converts Date to Date String in MM/DD/YEAR format
export const dateString = (date) =>
	new Date(date).toLocaleDateString('en-US', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		// timeZone: "America/New_York"
	});

// Formats phone numbers to (###) ###-####
export const formatPhoneNumber = (phone) => {
	const cleaned = ('' + phone).replace(/\D/g, '');
	const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

	if (match) {
		let international = match[1] ? '+1 ' : '';
		return [international, '(', match[2], ') ', match[3], '-', match[4]].join('');
	}

	return null;
};

// Removess format of phone numbers for storage
export const removePhoneNumberFormat = (phone) => {
	if (!phone) {
		return '';
	} else {
		return phone.replace(/\(|\)|-|_| /g, '');
	}
};

// Format UTC Date
export const formatUTCDate = (date) => {
	date = new Date(date);

	let mm = date.getMonth() + 1;
	let dd = date.getDate();
	let yyyy = date.getFullYear();

	if (dd < 10) {
		dd = '0' + dd;
	}

	if (mm < 10) {
		mm = '0' + mm;
	}

	return mm + '/' + dd + '/' + yyyy;
};

// set in cookie
export const setCookie = (key, value, expires, noMax = false) => {
	if (isClient) {
		if (expires) {
			cookies.set(key, value, {
				path: '/',
				maxAge: !noMax ? expires : null,
				expires: noMax ? expires : null,
			});
		} else {
			cookies.set(key, value, {
				path: '/',
			});
		}
	}
};

// remove from cookie
export const removeCookie = (key) => {
	if (isClient) {
		cookies.remove(key, {
			path: '/',
		});
	}
};

// get from cookie such as stored token
// will be useful when we need to make request to server with token
export const getCookie = (key) => {
	if (isClient) {
		return cookies.get(key);
	}
};

// localstorage
export const setLocalStorage = (key, value) => {
	const stringify = typeof value !== 'string';
	if (storageAvailable('localStorage')) {
		try {
			localStorage.setItem(key, stringify ? JSON.stringify(value) : value);
			return true;
		} catch (error) {
			return false;
		}
	} else {
		return false;
	}
};

export const getLocalStorage = (key) => {
	if (storageAvailable('localStorage')) {
		try {
			// HOTFIX // Can remove at a later date
			// Need to convert postalCode in object to postal_code
			// GuestLocation is sessionStorage and should always update on new session
			if ('userlocation' === key.toLowerCase() && localStorage.getItem(key)) {
				const object = JSON.parse(localStorage.getItem(key));
				if (object.postalCode) {
					object.postal_code = object.postalCode;
					delete object.postalCode;
					localStorage.setItem(key, JSON.stringify(object));
				}
			}
			// END HOTFIX
			let value = localStorage.getItem(key);

			// ensure object if supposed to be
			if (typeof value === 'string' && ((value.startsWith('{') && value.endsWith('}')) || (value.startsWith('[') && value.endsWith(']')))) {
				try {
					value = JSON.parse(value);
				} catch {}
			}

			return value;
		} catch (error) {
			return null;
		}
	} else {
		return null;
	}
};

export const removeLocalStorage = (key) => {
	if (storageAvailable('localStorage')) {
		try {
			localStorage.removeItem(key);
		} catch (error) {
			return false;
		}
	} else {
		return false;
	}
};

// sessionstorage
export const getSessionStorage = (key) => {
	if (storageAvailable('sessionStorage')) {
		try {
			let value = sessionStorage.getItem(key);

			// ensure object if supposed to be
			if (typeof value === 'string' && ((value.startsWith('{') && value.endsWith('}')) || (value.startsWith('[') && value.endsWith(']')))) {
				try {
					value = JSON.parse(value);
				} catch {}
			}

			return value;
		} catch (error) {
			return null;
		}
	} else {
		return null;
	}
};

export const setSessionStorage = (key, value, stringify = true) => {
	if (storageAvailable('sessionStorage')) {
		try {
			// eslint-disable-next-line no-console -- testing only
			sessionStorage.setItem(key, stringify ? JSON.stringify(value) : value);
		} catch (error) {
			return false;
		}
	} else {
		return false;
	}
};

export const removeSessionStorage = (key) => {
	if (storageAvailable('sessionStorage')) {
		try {
			return sessionStorage.removeItem(key);
		} catch (error) {
			return null;
		}
	} else {
		return null;
	}
};

export const storageAvailable = (type) => {
	// accepts sessionstorage or localstorage
	// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage

	if (isClient) {
		var storage;
		try {
			storage = window[type];
			var x = '__storage_test__';
			storage.setItem(x, x);
			storage.removeItem(x);
			return true;
		} catch (e) {
			return (
				e instanceof DOMException &&
				// everything except Firefox
				(e.code === 22 ||
					// Firefox
					e.code === 1014 ||
					// test name field too, because code might not be present
					// everything except Firefox
					e.name === 'QuotaExceededError' ||
					// Firefox
					e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
				// acknowledge QuotaExceededError only if there's something already stored
				storage &&
				storage.length !== 0
			);
		}
	}
};

export const geoLocationSuccess = (pos) => [pos.coords.longitude, pos.coords.latitude];

export const isCrawler = () => {
	const crawlers = ['Googlebot'];
	let crawler = false;
	if (navigator && navigator.userAgent) {
		for (let i = 0; i < crawlers.length; i++) {
			if (navigator.userAgent.includes(crawlers[i])) {
				crawler = true;
				break;
			}
		}
	}
	return crawler;
};

// Initialize Datalayer
export const initDataLayer = (event, rest) => {
	if (isClient) {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ event: event, ...rest });
	}
};

/**
 * Parse query string parameters
 * @param {*} qs
 * @returns
 */
export const parseQuery = (qs) => {
	if (qs) {
		const params = {};
		qs.split('?')[1]
			.split('&')
			.forEach((entry) => {
				const values = entry.split('=');
				params[values[0]] = values[1];
			});
		return params;
	}
};

/**
 * Setting background color class
 * @param {String} color
 * @returns string for global class - setBackgroundColor(data.color)
 */
export const setBackgroundColor = (color) => {
	if (color) {
		switch (color.toLowerCase()) {
			case 'black':
				return 'bgBlack';
			case 'white':
				return 'bgWhite';
			case 'yellow':
				return 'bgYellow';
			case 'dark gray':
				return 'bgDarkGray';
			case 'light gray':
				return 'bgLightGray';
			default:
				return '';
		}
	} else {
		return false;
	}
};

/**
 * useLocalStorage Hook
 * @param {String}
 */
// move to hooks? not used anywhere currently...
// export const useLocalStorage = (key, initialValue) => {
// 	// State to store our value
// 	// Pass initial state function to useState so logic is only executed once
// 	const [storedValue, setStoredValue] = useState(() => {
// 		try {
// 			// Get from local storage by key
// 			const item = getLocalStorage(key);
// 			// Parse stored json or if none return initialValue
// 			return item ? JSON.parse(item) : initialValue;
// 		} catch (error) {
// 			// If error also return initialValue
// 			return initialValue;
// 		}
// 	});
// 	// Return a wrapped version of useState's setter function that ...
// 	// ... persists the new value to localStorage.
// 	const setValue = (value) => {
// 		try {
// 			const valueToStore = value instanceof Function ? value(storedValue) : value;
// 			// Save state
// 			setStoredValue(valueToStore);
// 			// Save to local storage
// 			if (typeof valueToStore !== 'boolean') {
// 				setLocalStorage(key, JSON.stringify(valueToStore));
// 			} else {
// 				setLocalStorage(key, valueToStore);
// 			}
// 		} catch (error) {}
// 	};
// 	return [storedValue, setValue];
// };

// convert number to currency
export function currencyFormat(amount) {
	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	}).format(amount * 0.01);
}

// invalidate paypal session
export function invalidatePaypalSession() {
	if (getCookie('PayPalSession')) {
		removeCookie('PayPalSession');
	}

	if (isClient) {
		let params = new URLSearchParams(document.location.search);
		for (let param of ['token', 'PayerID', 'paymentId']) {
			if (params.has(param)) {
				params.delete(param);
			}
		}

		params.toString.length ? window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`) : window.history.replaceState({}, '', `${window.location.pathname}`);
	}
}

// Monetate datalayer
export const monetateDataLayer = (name, content) => {
	if (isClient) {
		window.monetateQ = window.monetateQ || [];

		if (!content) {
			window.monetateQ.push([name]);
		} else {
			window.monetateQ.push([name, content]);
		}
	}
};

// Performance Functions
export const performanceMark = (name) => {
	if (isClient) {
		window.performance.mark(name);
	}
};

export const performanceMeasure = (name, start, end) => {
	if (isClient) {
		let LUX = window.LUX || {};
		window.performance.measure(name, start, end);
		let measure = window.performance.measure(name, start, end);
		LUX?.addData?.(measure.name, measure.duration);
	}
};

/**
 * Filter Content By Tags
 * @param {Array} tags - array of tags from contentstack
 * @param {Array} array - array of entries
 * @param {Boolean} shuffleResults - shuffle results (default: true)
 * @returns {Array} - array of entries filtered down by tags
 */
export const filterContentByTags = (tags, array, shuffleResults = true) => {
	let resultsArray = array.filter((node) => {
		const taxonomy = node.taxonomies.map((t) => t.term_uid);

		if (tags?.length > 1) {
			if (tags.some((name) => taxonomy.includes(name))) return node;
		} else {
			if (tags.every((name) => taxonomy.includes(name))) return node;
		}
	});

	return shuffleResults ? shuffle(resultsArray) : resultsArray;
};

export const htmlDecode = (input) => {
	if (!isClient) return;
	const tempDiv = document.createElement('div');
	tempDiv.innerHTML = input;
	return tempDiv.childNodes[0].nodeValue;
};

/**
 * Gets contrast color based on background hex value
 * @param {string} bgColor - Hex value of background color
 * @returns {'text-black'|'text-white'} - Class name for text color
 */
export const getContrastColor = (bgColor) => {
	if (!bgColor) return;

	let rgb = [parseInt(bgColor.slice(1, 3), 16), parseInt(bgColor.slice(3, 5), 16), parseInt(bgColor.slice(5, 7), 16)];

	let luminance = 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];

	return luminance > 128 ? 'text-black' : 'text-white';
};
